<template>
  <v-sheet class="salary" id="salary" style="height: calc(100vh - 140px)">
    <v-row>
      <v-layout class="">
        <!-- <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green darken-4" dark>mdi-calendar</v-icon>
                    </VAvatar>
                   
                    Total Staff
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                      >{{employees.length}}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col> -->
        <v-col md="4" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label me-0"
                    style="font-weight: 600; font-size: 18px"
                  >
                    <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="blue darken-4" dark>person</v-icon>
                    </VAvatar>
                    <!-- <v-icon
                      large
                      class="custom-icon-label me-2"
                      style="background-color: #90caf9; color: white"
                      >person</v-icon
                    > -->
                    TOTAL EMP..</v-list-item-title
                  >
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                      >{{employees?.length}}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="4" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #4caf4e">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <!-- <v-icon
                      large
                      class="custom-icon-label me-2"
                      style="background-color: #4caf4e; color: white"
                      >mdi-calendar</v-icon
                    > -->
                    ACTIVE EMP..</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600"
                      >{{employeesActive?.length}}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card class="mx-auto" style="border: 0.5px solid #e57373">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <!-- <v-icon
                      large
                      class="custom-icon-label me-2"
                      style="background-color: #e57373; color: white"
                      >mdi-calendar</v-icon
                    > -->
                    INACTIVE EMP..</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600"
                      >{{(employees?.length) - (employeesActive?.length)}}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-layout>
    </v-row>
    <v-card-text class="px-0 my-auto py-0">
      <v-row>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter by Year</label
          >
          <AutoCompleteInput
            hide-details
            @change="searchSales"

            class="mt-3"
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="yearList"
            id="segment"
          
            v-model="search_year"
            placeholder="Select Year"
          ></AutoCompleteInput>
        </v-col>
       
        <v-col class="mt-5 justify-content-end">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in salaryTableHeading"
                  :key="item.title + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <v-checkbox
                      :ripple="false"
                      hide-details
                      color="#0d47a1"
                      v-model="item.status"
                      class="m-0"
                    ></v-checkbox>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-icon>drag_indicator</v-icon>
                  </div>
                  <v-divider
                    v-if="index < customThead?.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- :to="{ name: 'create-salary', query: { t: new Date().getTime() } }" -->
            <!-- <v-btn
              :disabled="pageLoading"
              color="blue darken-4"
              depressed
              tile
              class="ml-2 white--text"
              @click="isSelectMonthDialog = true"
            >
            Create Salary Month
            </v-btn> -->
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in customThead"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="salaryList?.length">
                <tr
                  v-for="(row, idx) in salaryList"
                  :key="`_$_index_$_${idx}`"
                  :class="idx % 2 === 0 && 'blue lighten-5'"
                  class="salary-listing-tr"
                  link
                      v-on:click="routeToDetail(row)"
                >
                  <td v-for="(th, index) in customThead" :key="'key_' + index">
                    <div v-if="(th.title) === 'title' && th.status">
                      {{ row[(th.title)]?.value }}
                    </div>

                    <div v-else-if="th.field === 'action' && th.status">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn
                              v-on:click.stop.prevent="deleteConfirm(row.name, row.id)"
                              depressed
                              fab
                              dark
                              x-small
                            
                              color="green white--text"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="(th.field) === 'date' && th.status"
                     
                      class="cursor-pointer"
                    >
                    <v-chip
                  small
                  color="blue"
                  v-if="th.field === 'date' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row.month_name }} {{ row.year }}
                </v-chip>
              
                    </div>

                    <div
                      v-else-if="(th.field) === 'total_staff' && th.status"
                      
                      class="cursor-pointer"
                    >
                      <v-chip small color="#0D47A1" outlined>
                        <ValueTemplate
                          v-model="row.staff"
                          :title="staff"
                        ></ValueTemplate
                      ></v-chip>
                    </div>

                    <div
                      v-else-if="(th.field) === 'salary' && th.status"
                      
                      class="cursor-pointer"
                    >
                    <template
                          v-if="
                            row?.data"
                        >
                        {{formatMoneys(
                              row?.data
                                .map((entry) => entry.gross_earnings)
                                .reduce((total, current) => total + current, 0)
                            )
                          }}
                  </template>
                    </div>
                    <div
                      v-else-if="(th.field) === 'gross_earning' && th.status"
                      
                      class="cursor-pointer"
                    >
                    <template
                          v-if="
                            row?.data"
                        >
                        {{formatMoneys(
                              row?.data
                                .map((entry) => entry.gross_earnings)
                                .reduce((total, current) => total + current, 0)
                            )
                          }}
                  </template>
                    </div>
                    <div
                      v-else-if="(th.field) === 'net_income' && th.status"
                      
                      class="cursor-pointer"
                    >
                    <template
                          v-if="
                            row?.data"
                        >
                        {{formatMoneys(
                              row?.data
                                .map((entry) => entry.net_income)
                                .reduce((total, current) => total + current, 0)
                            )
                          }}
                  </template>
                    </div>

                    <div
                      v-else-if="(th.field) === 'deduction' && th.status"
                      
                      class="cursor-pointer"
                    >
                    <template
                          v-if="
                            row?.data"
                        >
                        {{formatMoneys(
                              row?.data
                                .map((entry) => entry.gross_deduction)
                                .reduce((total, current) => total + current, 0)
                            )
                          }}
                  </template>
                  
                     
                    </div>
                    <div
                      v-else-if="(th.field) === 'penalty' && th.status"
                     
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row.penalty"
                        :title="penalty"
                      ></ValueTemplate>
                    </div>
                    <div
                      v-else-if="(th.field) === 'loan' && th.status"
                   
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row.loan"
                        :title="loan"
                      ></ValueTemplate>
                    </div>
                    <div
                      v-else-if="(th.title) === 'total_loan' && th.status"
                     
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[(th.title)].value"
                        :title="row[(th.title)].key"
                      ></ValueTemplate>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no salary at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
           
          </v-layout>
        </template>
      </v-col>
    </v-row>

    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegments()"
    />
    <SalaryMonthDialog
    :employee="employees"
      :dialog="isSelectMonthDialog"
      @close="isSelectMonthDialog = false"
      @success="getSegments()"

    ></SalaryMonthDialog>
    <Dialog :dialog="dialog" fullscreen>
      <template v-slot:title>
        <v-flex class="d-flex justify-content-between">
          <p class="my-auto py-0">SALARY</p>
          <v-btn
            class="my-auto py-0"
            depressed
            tile
            :disabled="pageLoading"
            v-on:click="dialog = false"
          >
            Close
          </v-btn></v-flex
        >
      </template>
      <template v-slot:body>
        <SalaryList></SalaryList>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import SalaryList from "@/view/components/SalaryList";
import ValueTemplate from "@/view/components/ValueTemplate";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { GET_SALARYS } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
import { QUERY} from "@/core/services/store/request.module";
import { filter, map } from "lodash";
import PosMixin from "@/core/mixins/pos.mixin";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import SalaryMonthDialog from "./SalaryMonthDialog.vue";
export default {
  mixins: [PosMixin],

  name: "employee-create",
  title: "Create Salary",
  components: { ValueTemplate, AutoCompleteInput, CreateSegment, Dialog, SalaryList, SalaryMonthDialog },
  data() {
    return {
      pageLoading: false,
      employees:[],
      search_year: new Date().getFullYear(),
      yearList: [],
      year:new Date().getFullYear(),
      employeesActive:[],
      customThead:[],
      defaultColShow:[],
      salaryList:[],
      isSelectMonthDialog: false,
      dialog: false,
      generateSegmentDialog: false,
      deleteDialog: false,
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      loadPagination: false,
      totalPages: null,
      deleteEndpoint: null,
      salaryEmployeeList: [
        {
          action: { value: "", key: "ACTION" },
          month: { value: "JUNE 2023", key: "MONTH" },
          total_staff: { value: "120", key: "TOTAL STAFF" },
          total_salary: { value: "12000.00", key: "TOTAL SALARY" },
          total_deducation: { value: "3050.00", key: "TOTAL DEDUCATION" },
          penalty: { value: "1000.00", key: "PENTALTY" },
          total_provision: { value: "", key: "TOTAL PROVISION" },
          total_loan: { value: "12000.00", key: "TOTAL LOAN" },
        },
        {
          action: { value: "", key: "ACTION" },
          month: { value: "APRIL 2023", key: "MONTH" },
          total_staff: { value: "120", key: "TOTAL STAFF" },
          total_salary: { value: "12000.00", key: "TOTAL SALARY" },
          total_deducation: { value: "3050.00", key: "TOTAL DEDUCATION" },
          penalty: { value: "1000.00", key: "PENALTY" },
          total_provision: { value: "", key: "TOTAL PROVISION" },
          total_loan: { value: "12000.00", key: "TOTAL LOAN" },
        },
      ],
      employeeList: [],
      dragStartIndex: null,
      dragOverIndex: null,
      salaryTableHeading: [
        { title: "ACTION", status: true },
        { title: "MONTH", status: true },
        { title: "TOTAL STAFF", status: true },
        { title: "TOTAL SALARY", status: true },
        { title: "TOTAL DEDUCATION", status: true },
        { title: "PENALTY", status: true },
        { title: "TOTAL PROVISION", status: true },
        { title: "TOTAL LOAN", status: true },
      ],
    };
  },
  
 

  methods: {
    searchSales() {

       this.getSegments();    },
    yearlist() {
      let currentYear = new Date().getFullYear();
      let startYear = 2022;
      this.yearList = [];
      for (let year = startYear; year <= currentYear; year++) {
        this.yearList.push(year);
      }
      console.log(this.yearList);
    },
    routeToDetail(row) {
      console.log(row,'row')
      this.$router.push({
        name: "salary-detail",
        params: { id: row.month_number },
        query: { t: new Date().getTime(), year: row.year },
      });
    },
    getEmployee() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "/get-employee",
        })
        .then((data) => {
          _this.employees = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getEmployeeActive() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "/get-employee-active",
        })
        .then((data) => {
          _this.employeesActive = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    // getSalary() {
    //   this.dialog = true;
    // },
    
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.salaryTableHeading[this.dragStartIndex];
      this.salaryTableHeading.splice(this.dragStartIndex, 1);
      this.salaryTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    goToSegmentDetail(id) {
      this.$router.push({
        name: "broadcast-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-broadcast",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
   
    async getSegments() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, showing_string, totalPages,theads,
 } = await GET_SALARYS(
          this.currentPage,
          this.search_year
          
          //this.per_page
        );
        this.salaryList = rows;
        this.showingString = showing_string;
        this.totalPages = totalPages;
        let thead = theads;

        this.defaultColDefs = thead;

        this.customThead = [];
        for (let index = 0; index < thead?.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }
        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
        this.isSelectMonthDialog = false;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getSegments();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(name, id) {
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getSegments();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getSegments();
    this.yearlist();
    this.getEmployee();
    this.getEmployeeActive();
    this.$store.dispatch(SET_SEARCH_BAR, true);

    this.$store.dispatch(SET_SEARCH_TITLE, "Salary");

  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
};
</script>
<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 370px);
}
.salary-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.salary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
